import React, { useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import toast from "react-hot-toast"
import axios from "axios"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

const ContactHeroStyles = styled(Hero)`
  .gatsby-image-wrapper {
    filter: brightness(80%);
  }
`

const ContactStyles = styled.div`
  min-height: 300px;
  padding-bottom: 1em;

  h2 {
    padding: 2em 0;
    text-align: center;
    margin-bottom: 0;
  }

  .thank-you-banner {
    font-family: "prompt", Arial, Helvetica, sans-serif;
    max-width: 700px;
    margin: 0 auto 1em;
    padding: 1em;
    border-radius: 6px;
    background: none;
    color: #000;
    border: 1px solid #000;
    text-align: center;
  }

  .form-row {
    display: flex;
    justify-content: space-between;

    .contact-form {
      font-family: "prompt", Arial, Helvetica, sans-serif;
      width: 48%;
      padding: 2em;
      margin: 0 auto 3em;
      background: #fff;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

      .hidden {
        display: none;
      }

      h2,
      h3,
      h4 {
        margin-bottom: 0.5em;
        text-transform: capitalize;
      }

      .form-field {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 500px;
        margin: 0 auto 1em;

        input,
        textarea {
          padding: 10px;
          font-size: 1.1em;
          font-family: "raleway", Arial, Helvetica, sans-serif;
        }
      }

      button {
        background: #000;
        color: #fff;
        border-radius: 6px;
        border: none;
        padding: 10px;
        font-size: 1.1em;
        width: 100%;
        max-width: 500px;
        margin: 1em auto;
        font-size: 1.2rem;
        cursor: pointer;
        transition: background 0.2s;
        text-decoration: none;

        &:hover {
          background: rgba(0, 0, 0, 0);
          color: #000;
          border: 3px solid #000;
          transition: background 0.2s;
        }
      }
    }
  }

  @media (max-width: 800px) {
    .contact-form {
      width: 75% !important;
    }
  }
`

export default function ContactPage({ data, location }) {
  const submitted = new URLSearchParams(location.search).get("submitted")
  const siteTitle = "Encore Wheels - Contact Us"
  const [isSending, setIsSending] = useState(false)
  const [honey, setHoney] = useState(undefined)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")

  const handleSubmit = async (event) => {
    event.preventDefault()

    const body = {
      honey,
      name,
      email,
      subject,
      message,
    }

    if (body.honey !== undefined) {
      toast.error(
        "Your request cannot be submitted. Please refresh and try again."
      )
    } else {
      try {
        setIsSending(true)
        const response = await axios.post(`/.netlify/functions/contact`, body)
        console.log("RESPONSE", response)
        toast.success("Your response was sucessfully submitted!")
      } catch (ex) {
        console.error(ex)
        toast.error("Error sending your response!")
      }

      setIsSending(false)
    }
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Encore Wheels"
        keywords={[
          `carbon wheels`,
          `carbon fiber wheels`,
          `carbon bicycle wheels`,
          `high performance bicycle wheels`,
          `bike wheels`,
          `bmx cruiser wheels`,
          `29er bmx`,
          `fixed gear wheels`,
          `fixed wheels`,
          `fixie wheels`,
          `track wheels`,
          `road bike wheels`,
          `carbon wheelset`,
          `usa made wheels`,
        ]}
      />
      <ContactHeroStyles
        image={data.file}
        title={data.file.name}
        alt="Encore Wheels Headquarters"
      >
        <h1>Contact Us!</h1>
        <p>Call us at (248)-714-6028 or fill out the form below</p>
      </ContactHeroStyles>
      <ContactStyles>
        <h2>Get ahold of us by filling out the form below!</h2>
        {submitted && (
          <div className="thank-you-banner">
            Thank you for reaching out! Some one will get back to you as soon as
            we can!
          </div>
        )}
        <div className="form-row">
          <form
            method="post"
            name="Contact Form"
            className="contact-form"
            netlify-honeypot="hpfield"
            onSubmit={handleSubmit}
          >
            <input
              className="hidden"
              name="hpfield"
              value={honey}
              onChange={(e) => setHoney(e.target.value)}
            />
            <div className="form-field">
              <label htmlFor="name">Your Name</label>
              <input
                id="name"
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-field">
              <label htmlFor="email">Your Email</label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-field">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                name="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
            </div>
            <div className="form-field">
              <label htmlFor="message">Your Message</label>
              <textarea
                name="message"
                rows="3"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </div>
            <button type="submit" disabled={isSending}>
              {isSending ? "Sending..." : "Send"}
            </button>
          </form>
        </div>
      </ContactStyles>
    </Layout>
  )
}

export const query = graphql`
  query ContactImage {
    file(
      relativePath: { eq: "contact.jpeg" }
      sourceInstanceName: { eq: "assets" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(quality: 90, width: 390, layout: CONSTRAINED)
      }
    }
  }
`
